<template>
  <div class="profile-container gap24 columnd w100">
    <PersonalInfo />
    <div class="lt gap24 rowd">
      <Lists />
      <Table />
    </div>
  </div>
</template>

<script>
import Table from "./Table.vue";
import PersonalInfo from "./PersonalInfo.vue";
import Lists from "./Lists.vue";
export default {
  name: "Panel",
  components: {
    PersonalInfo,
    Table,
    Lists,
  },
};
</script>

<style lang="scss" scoped>
@media only screen and(max-width:1000px){
  .lt {
    flex-direction: column;
  }
}
</style>
