<template>
  <div class="columnd mini-container">
    <div class="w100 columnd gap24">
      <h3 class="f14-700 c-darkBlue text-start">Verifications</h3>
      <div class="columnd rwos-container gap16">
        <div class="rowd w100 space-between align-center">
          <div class="rowd gap4 align-center">
            <inline-svg :src="require('../../../assets/Icons/mobile.svg')" />
            <span class="f14-400 c-darkGrey"> Phone verified </span>
          </div>
          <inline-svg
            class="fill-green"
            :src="require('../../../assets/Icons/tik.svg')"
          />
        </div>
        <div class="rowd w100 space-between align-center">
          <div class="rowd gap4 align-center">
            <inline-svg
              style="width:18px;height:21px"
              class="fill-darkGrey"
              :src="require('../../../assets/Icons/email.svg')"
            />
            <span class="f14-400 c-darkGrey"> Email verified </span>
          </div>
          <inline-svg
            class="fill-green"
            :src="require('../../../assets/Icons/tik.svg')"
          />
        </div>
        <div class="rowd w100 space-between align-center">
          <div class="rowd gap4 align-center">
            <inline-svg :src="require('../../../assets/Icons/idcard.svg')" />
            <span class="f14-400 c-darkGrey"> Id verified </span>
          </div>
          <inline-svg
            class="fill-green"
            :src="require('../../../assets/Icons/tik.svg')"
          />
        </div>
        <div class="rowd w100 space-between align-center">
          <div class="rowd gap4 align-center">
            <inline-svg :src="require('../../../assets/Icons/location.svg')" />
            <span class="f14-400 c-darkGrey"> Address verified </span>
          </div>
          <inline-svg
            class="fill-green"
            :src="require('../../../assets/Icons/tik.svg')"
          />
        </div>
      </div>
    </div>
    <div class="w100 columnd gap24">
      <h3 class="f14-700 c-darkBlue text-start">Info</h3>
      <div class="columnd rwos-container gap16">
        <div class="rowd w100 space-between align-center">
          <span class="f14-400 c-darkGrey"> Location </span>
          <span class="f14-400 c-ddGrey"> anyWhere But India </span>
        </div>
        <div class="rowd w100 space-between align-center">
          <span class="f14-400 c-darkGrey"> Trade Partners </span>
          <span class="f14-400 c-ddGrey"> 32 </span>
        </div>
        <div class="rowd w100 space-between align-center">
          <span class="f14-400 c-darkGrey"> Trades </span>
          <span class="f14-400 c-ddGrey"> 234 </span>
        </div>
        <div class="rowd w100 space-between align-center">
          <span class="f14-400 c-darkGrey"> Joined </span>
          <span class="f14-400 c-ddGrey"> 2 Days ago </span>
        </div>
        <div class="rowd w100 space-between align-center">
          <span class="f14-400 c-darkGrey"> Trusted by </span>
          <span class="f14-400 c-ddGrey"> 87 People </span>
        </div>
        <div class="rowd w100 space-between align-center">
          <span class="f14-400 c-darkGrey"> Blocked by </span>
          <span class="f14-400 c-ddGrey"> 87 People </span>
        </div>
      </div>
    </div>
    <div class="w100 columnd gap24">
      <h3 class="f14-700 c-darkBlue text-start">Trade volume</h3>
      <div class="columnd rwos-container gap16" v-for="i in 3" :key="i">
        <div class="rowd w100 space-between align-center">
          <div class="rowd gap4 align-center">
            <img
              class="coin24"
              src="../../../assets/test/ETHEREUM.png"
              alt=""
            />
            <span class="f14-400 c-darkGrey"> ETH </span>
          </div>
          <span class="f14-400 c-darkGrey"> 0.737 ETH </span>
        </div>
      </div>
      <span class="f12-400 c-darkGrey text-start"> and 10 more...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListsProfile",
};
</script>

<style lang="scss" scoped>
@media only screen and(max-width:1000px) {
  .mini-container {
    width: 100% !important;
    flex-direction: row !important;
    flex-wrap: wrap;
  }
}
.mini-container {
  gap: 40px;
  width: 25%;
}
</style>
